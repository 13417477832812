.recovery-password-form {
  width: 100%;
}

.recovery-password-form input {
  border: 1px solid #c5d5fd;
  border-radius: 5px;
  width: 100%;
  padding: 12px;
}

.recovery-password-form select:focus,
.recovery-password-form input:focus {
  outline: none;
}

.recovery-password-form ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #c5d5fd;
  opacity: 1; /* Firefox */
}

.recovery-password-form :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #c5d5fd;
}

.recovery-password-form ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #c5d5fd;
}

.recovery-password-form .form-title {
  color: #4e5f71;
  font-size: 14px;
}

.recovery-password-form .form-user-info {
  color: #8894a1;
  font-size: 12px;
  border: 1px solid #8894a1;
  width: 100%;
  border-radius: 5px;
  padding: 8px;
  margin: 10px;
}
.recovery-password-form .form-user-info-icon {
  color: #8894a1;
}

.form-user-info-wrapper {
  display: flex;
  align-items: center;
}

.change-password-button {
  background: #5c89fb;
  color: white;
  padding: 12px;
  border: 1px solid #5c89fb;
  border-radius: 5px;
  margin-top: 15px;
  width: 100%;
  font-size: 10px;
}

.change-password-button:hover {
  cursor: pointer;
}

.go-to-login-button {
  color: #6791fa;
  text-decoration: underline;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
  border: none;
  background-color: transparent;
  width: 100%;
}

.change-password-button-disabled {
  background: #CDD4DB;
  color: rgb(70, 70, 70);
  padding: 12px;
  border: 1px solid #CDD4DB;
  border-radius: 5px;
  margin-top: 15px;
  width: 100%;
  font-size: 10px;
  pointer-events: none;
  cursor: not-allowed;
}

.go-to-login-button:hover {
  cursor: pointer;
  color: #5c89ff;
}

.divider {
  border-top: 1px solid #5c89fb;
  margin: 20px;
}

.login-form-fields-error {
  font-size: 12px;
  color: #ef9f94;
}

.error-message-info {
  margin-top: 10px;
  font-size: 10px;
  border: 1px solid #ef9f94;
  background-color: #ef9f944f;
  border-radius: 5px;
  padding: 5px;
}

.error-message-success {
  margin-top: 10px;
  font-size: 10px;
  border: 1px solid #b1df8b;
  background-color: #bdef9494;
  border-radius: 5px;
  padding: 5px;
}
